<template lang="html">
  <section class="hero flex fj-center fa-center fd-col">
    <div class="wrapper flex fj-center fa-center fd-col">
      <h1>{{ title}}</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'hero',
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="css">
  .hero{
    position: relative;
    margin-top: var(--nav-h);
    height: var(--hero-height);
    width: 100%;
    border-top: 1px solid var(--color-prim);
    transition: border var(--lin-cool);
  }
  .--mode-light.--prim-bright .hero{
    --color-prim: var(--color-prim-dark);
  }
  .hero .container{
    width: 100%;
  }
</style>
