<template lang="html">
  <div class="about">
    <hero title="who I am &amp; what I do"/>
    <bodyContent/>
  </div>
</template>

<script>
import hero from '@/components/hero'
import bodyContent from '@/components/bodyContent'

export default {
  name: "About",
  components:{
    hero,
    bodyContent
  },
  mounted(){
    // secrets check
    console.log("apiKey is " +process.env.semantix_apiKey)
  }
}
</script>

<style>
.about .container{
  padding: 0 var(--container-padding);
  position: relative;
  margin: 0 auto;
}
</style>
