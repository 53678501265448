<template lang="html">
  <section class="body-content">
    <div class="container flex fa-start fj-center">
      <main>
        <h2>Hello World !</h2>
        <p>
          My name is Yannick. I like a lot of things in life like doing sports, listening to music, contemplating visual arts, savouring tasty food, going for a walk in nature. I would say that I'm rather curious, hard-working, enthousiastic and sometimes perfectionist.</br>
          I like to work within a team, participate in brainstormings, brings ideas. I like to be part of a pushing-foward team that is looking to make things work.</br>
          I'm also able to work in autonomy, to make decisions and take initiatives without anyone around.</br>
        </p>

        <hr>
        <h2>Front End Developer</h2>
        <p>
          I have 5 years of experience as a web developer. HTML, CSS, JS &amp; Node JS are the languages I manipulate to create the most delightful web experiences possible. I love to work on interactive web projects involving new technologies and new ways of thinking.
        </p>

        <hr>
        <h2>Visual Designer</h2>
        <p>
          I have also been a visual designer for 9 years working for clients like <em>Rotary Club</em>, <em>Autodesk Revit</em>, <em>Candius Stearns</em> and <em>Finance Technology Leverage</em>, amongst others, engaging myself in the following roles:
        </p>
        <ul>
          <li>Branding designer</li>
          <li>Print designer</li>
          <li>UX designer</li>
          <li>Illustrator</li>
        </ul>

        <hr>
        <h2>Projects I work on</h2>
        <ul>
          <li>Website</li>
          <li>Single Page (Web) Application (SPA)</li>
          <li>CSS Framework</li>
          <li>A.P.I.</li>
          <!-- <li>HTML5 Video Game</li> -->
        </ul>
        <hr>
        <h2>I write code that is</h2>
        <ul>
          <li>Clean</li>
          <li>Documented</li>
          <li>Readable</li>
          <li>Modular</li>
          <li>Scalable</li>
          <li>Maintainable</li>
        </ul>
        <hr>
        <h2>Coding methodologies</h2>
        <ul>
          <li>BEM</li>
          <li>Dry</li>
          <li>Functional programming</li>
          <li>Object Oriented programming</li>
          <li>Unit testing</li>
          <li>Continuous Integration</li>
        </ul>
        <hr>
        <h2>Working methodologies</h2>
        <ul>
          <li>SCRUM (Agile)</li>
          <li>ITIL</li>
        </ul>
        <hr>
      </main>
      <aside class="">
        <h2 class="aside-title">Languages</h2>
        <ul>
          <li class="word-tag">HTML</li>
          <li class="word-tag">CSS</li>
          <li class="word-tag">SVG</li>
          <li class="word-tag">JavaScript</li>
          <li class="word-tag">Node JS</li>
        </ul>
        <!-- <hr> -->
        <h2 class="aside-title">Librairies</h2>
        <ul>
          <li class="word-tag">Vue JS</li>
          <li class="word-tag">Express</li>
          <li class="word-tag">Firebase / Firestore</li>
          <li class="word-tag">Webpack</li>
          <li class="word-tag">NPM</li>
          <li class="word-tag">Yarn</li>
          <li class="word-tag">GSAP</li>
          <li class="word-tag">Git</li>
          <li class="word-tag">Jest</li>
        </ul>
      </aside>
    </div>
  </section>
</template>

<script>
export default {
  name: 'bodyContent',
  data(){
    return{

    }
  }
}
</script>

<style>
.body-content{
  padding-bottom: calc(var(--page-padding)/1);
  border-top: 1px solid var(--color-prim);
  transition: border var(--lin-norm);
  color: var(--over-bg);
}
.--mode-light.--prim-bright .body-content{
  border-top: 1px solid var(--color-prim-dark);
}
.body-content main{
  padding-top: var(--main-padding);
  padding-bottom: var(--main-padding);
  width: fit-content;
  margin-right: 24px;
}
.body-content aside{
  position: -webkit-sticky;
  position: sticky;
  top: calc( var(--nav-h) );
  padding-left: 16px;
  padding-top: var(--main-padding);
  padding-bottom: var(--main-padding);
  width: var(--aside-width);
  border-left: 1px solid var(--color-prim);
  transition: border var(--lin-norm);
  overflow-y: hidden;
}
.--mode-light.--prim-bright .body-content aside{
  border-left: 1px var(--color-prim-dark) solid;
}
.body-content aside hr,
.body-content main hr{
  border: 0;
  box-shadow: 0 0 0 0.5px var(--color-prim);
  height: 0px;
  margin: 16px auto 24px;
  background: var(--color-prim);
  transition: box-shadow var(--lin-norm);
}
.body-content main hr{
  box-shadow: 0 0 0 0.5px var(--color-prim-20);
  background: var(--color-prim);
}
.body-content main li{
  list-style-type: square;
  margin-left: 24px;
}
.body-content main ul{
  margin-top: 16px;
}
.body-content main h1,
.body-content main h2,
.body-content main h3,
.body-content main h4{
  color: var(--over-prim);
  background-color: var(--color-prim);
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
}
.body-content aside h1,
.body-content aside h2,
.body-content aside h3,
.body-content aside h4{
  color: var(--over-accent);
  background-color: var(--color-accent);
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
}
.body-content aside h1,
.body-content aside h2,
.body-content aside h3,
.body-content aside h4{
  margin-top: 8px;
  margin-bottom: 24px;
}
.body-content aside ul{
  padding: 2px;
  margin-bottom: 16px;
}
@media screen and (max-width: 960px){
  .body-content h2{
    /* font-size: 1.2rem; */
  }
}
@media screen and (max-width: 768px){
  .--mode-light.--prim-bright .body-content aside{
    border-left: 0 var(--color-prim-dark) solid;
  }
  .body-content aside{
    position: unset;
    width: 100%;
    border: none;
    padding-left: 0;
    padding-top: 0;
    height: auto;
  }
  .body-content .container{
    flex-direction: column;
  }
  .body-content main{
    padding-top: var(--main-padding);
    padding-bottom: var(--main-padding);
    margin-right: 0;
  }
  .body-content aside .word-tag{
    font-size: 0.8rem;
  }
  .body-content h2{
    font-size: 1.1rem;
  }
}
.--mode-light.--prim-bright .body-content aside .word-tag,
.--mode-light.--prim-bright .body-content hr{
  --color-prim: var(--color-prim-dark);
}
.body-content aside .word-tag{
  display: inline-block;
  font-size: 0.9rem;
  margin-bottom: 12px;
  padding: 4px 16px;
  margin-right: 12px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px var(--color-prim);
  transition: background-color var(--lin-fast);
  cursor: pointer;
}
.body-content aside .word-tag:hover{
  background-color: var(--color-prim-20);
}
.word-like{
  font-size: 0.9rem;
}
.aside-title{
  font-size: 1rem;
}
</style>
